import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    user: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: true
});

export const LoginAdmin = createAsyncThunk("admin/loginAdmin", async (user, thunkAPI) => {
    try {
        const response = await api.post('/loginAdmin', {
            email: user.email,
            password: user.password
        });
        return response.data;
    } catch (error) {
        console.error('Login error:', error.response?.data || error.message);
        return thunkAPI.rejectWithValue(error.response?.data?.msg || 'An error occurred');
    }
});

export const getMeAdmin = createAsyncThunk("admin/getMeAdmin", async (_, thunkAPI) => {
    try {
        console.log("Fetching user data");
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Me`, { withCredentials: true });
        console.log("User data response:", response.data);
        return response.data;
    } catch (error) {
        console.error("GetMe error:", error.response?.data || error.message);
        return thunkAPI.rejectWithValue(error.response?.data?.msg || "An error occurred");
    }
});
export const LogOutAdmin = createAsyncThunk("admin/logOutAdmin", async() => {
    await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/logoutAdmin`, { withCredentials: true });
});

export const authAdminSlice = createSlice({
    name: "authAdmin",
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(LoginAdmin.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(LoginAdmin.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
        });
        builder.addCase(LoginAdmin.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        });

        builder.addCase(getMeAdmin.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMeAdmin.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
        });
        builder.addCase(getMeAdmin.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        });
    }
});

export const { reset } = authAdminSlice.actions;
export default authAdminSlice.reducer;
