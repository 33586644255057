import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, NavLink} from 'react-router-dom';
import { LoginUser, reset } from "../fitur/AuthKaryawan";
import "./Login.css";
import background from "../img/backgound.png"

const LoginKaryawan = () => {
  const [nik, setNik] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.authKaryawan
  );
  useEffect(() => {
    const savedNik = localStorage.getItem('nik');
    const savedPassword = localStorage.getItem('password');
    if (savedNik && savedPassword) {
      setNik(savedNik);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);
  useEffect(() => {
    if (user || isSuccess) {
      navigate("/dashboard");
    }
    dispatch(reset());
  }, [user, isSuccess, dispatch, navigate]);
  const Auth = (e) => {
    e.preventDefault();
    if (rememberMe) {
      localStorage.setItem('nik', nik);
      localStorage.setItem('password', password);
    } else {
      localStorage.removeItem('nik');
      localStorage.removeItem('password');
    }
    dispatch(LoginUser({ nik, password }));
  };
  // const Auth = (e) => {
  //   e.preventDefault();
  //   if (!nik || !password) {
     
  //     alert("NIK dan password harus diisi!");
  //     return;
  //   }
  //   dispatch(LoginUser({ nik, password }));
  // };
const gambar = background
  return (
    <div className="login-container">
    <div className="login-left">
      <img src={gambar} alt="Login Illustration" className="login-illustration" />
    </div>
    <div className="login-right">
      <form onSubmit={Auth} className="login-box">
        <h1 className="title">Welcome user 👋</h1>
        <p>Please sign-in to your account and start the work</p>
        {isError && <p className="error-message">{message}</p>}
        <div className="field">
          <label className="label">Nik</label>
          <div className="control">
            <input
              type="number"
              className="input"
              value={nik}
              onChange={(e) => setNik(e.target.value)}
              placeholder="Enter your nik"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Password</label>
          <div className="control">
            <input
              type="password"
              className="input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </div>
        </div>
        <div className="field">
          <label className="checkbox">
            <input 
              type="checkbox" 
              checked={rememberMe} 
              onChange={(e) => setRememberMe(e.target.checked)} 
            /> Remember me
          </label>
        </div>
        <div className="field">
          <button type="submit" className="button is-primary is-fullwidth">
            {isLoading ? "Loading..." : "Login"}
          </button>
        </div>
        <div className="field">
          <NavLink to="/loginadmin" className="button is-secondary is-halfwidth">
            Go to Admin Login
          </NavLink>
        </div>
      </form>
    </div>
  </div>

  );
};

export default LoginKaryawan;
