import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
//import { Form, Button, Alert, Container, Row, Col, Card } from 'react-bootstrap';
import "../admin/css/gaji.css";

export const CreateGaji = () => {
  const [tanggal_periode, setTanggalPeriode] = useState('');
  const [periode, setPeriode] = useState('');
  const [nominal, setNominal] = useState('');
  const [tambahan, setTambahan] = useState('');
  const [potongan, setPotongan] = useState('');
  const [keterangan, setKeterangan] = useState('');
  const [id, setKaryawan] = useState('');
  const [karyawans, setKaryawans] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/karyawan`);
        if (Array.isArray(response.data.karyawan)) {
          setKaryawans(response.data.karyawan);
        } else {
          setKaryawans([]);
        }
      } catch (error) {
        setError('Gagal mengambil data karyawan.');
      }
    };
    fetchData();
  }, []);

  // Fungsi untuk memformat angka dengan pemisah ribuan
  const formatRupiah = (value) => {
    if (!value) return '';
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  // Handle perubahan input untuk nominal
  const handleNominalChange = (e) => {
    const value = e.target.value.replace(/\./g, ''); // Hilangkan titik saat input
    setNominal(formatRupiah(value)); // Format angka dengan titik
  };

  // Handle perubahan input untuk tambahan
  const handleTambahanChange = (e) => {
    const value = e.target.value.replace(/\./g, ''); // Hilangkan titik saat input
    setTambahan(formatRupiah(value)); // Format angka dengan titik
  };

  // Handle perubahan input untuk potongan
  const handlePotonganChange = (e) => {
    const value = e.target.value.replace(/\./g, ''); // Hilangkan titik saat input
    setPotongan(formatRupiah(value)); // Format angka dengan titik
  };

  const createGaji = async (e) => {
    e.preventDefault();
    const nominalNumber = parseFloat(nominal.replace(/\./g, '')); // Hilangkan titik sebelum kirim
    const tambahanNumber = parseFloat(tambahan.replace(/\./g, '')); // Hilangkan titik sebelum kirim
    const potonganNumber = parseFloat(potongan.replace(/\./g, '')); // Hilangkan titik sebelum kirim

    const data = {
      tanggal_periode,
      periode,
      nominal: nominalNumber,
      tambahan: tambahanNumber,
      potongan: potonganNumber,
      keterangan,
      id
    };

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/creategaji`, data);
      setMessage('Gaji berhasil dibuat');
      navigate('/datagaji');
    } catch (error) {
      setError(error.response?.data?.msg || 'Terjadi kesalahan');
    }
  };

  return (
    <div className="columns is-centered mt-5">
      <div className="column is-half">
        <div className="card">
          <div className="card-content">
            <h2 className="subtitle has-text-centered mb-4">Tambah Gaji Baru</h2>

            {/* Pesan Notifikasi */}
            {message && <div className="notification is-success">{message}</div>}
            {error && <div className="notification is-danger">{error}</div>}

            {/* Form Tambah Gaji */}
            <form onSubmit={createGaji}>
              {/* Pilih Karyawan */}
              <div className="field">
                <label className="label">Pilih Karyawan</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select value={id} onChange={(e) => setKaryawan(e.target.value)} required>
                      <option value="">Pilih Karyawan</option>
                      {karyawans.map((karyawan) => (
                        <option key={karyawan.id} value={karyawan.id}>
                          {karyawan.nama_lengkap}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {/* Tanggal Periode dan Periode */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Tanggal Periode</label>
                    <div className="control">
                      <input
                        type="date"
                        className="input"
                        value={tanggal_periode}
                        onChange={(e) => setTanggalPeriode(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Periode</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select value={periode} onChange={(e) => setPeriode(e.target.value)} required>
                          <option value="">Pilih Periode</option>
                          <option value="januari">Januari</option>
                          <option value="februari">Februari</option>
                          <option value="maret">Maret</option>
                          <option value="april">April</option>
                          <option value="mei">Mei</option>
                          <option value="juni">Juni</option>
                          <option value="juli">Juli</option>
                          <option value="agustus">Agustus</option>
                          <option value="september">September</option>
                          <option value="oktober">Oktober</option>
                          <option value="november">November</option>
                          <option value="desember">Desember</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Nominal dan Tambahan */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Nominal</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={nominal}
                        onChange={handleNominalChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Tambahan</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={tambahan}
                        onChange={handleTambahanChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Potongan */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Potongan</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={potongan}
                        onChange={handlePotonganChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Keterangan */}
              <div className="field">
                <label className="label">Keterangan</label>
                <div className="control">
                  <textarea
                    className="textarea"
                    value={keterangan}
                    onChange={(e) => setKeterangan(e.target.value)}
                    rows="3"
                  />
                </div>
              </div>

              {/* Tombol Submit */}
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-primary is-fullwidth">
                    Buat Gaji
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
