import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import "../admin/css/dataKaryawan.css"

axios.defaults.withCredentials = true;

const fetcher = (url) => axios.get(url).then(res => res.data);

export const DataKaryawan = () => {
  const { data: karyawan, error, mutate } = useSWR(`${process.env.REACT_APP_API_BASE_URL}/karyawan`, fetcher);
  const [searchTerm, setSearchTerm] = useState(''); 

  if (error) return <div>Error loading data</div>;
  if (!karyawan) return <div>Loading...</div>;

  // Handle both array and object responses
  const karyawanList = Array.isArray(karyawan) ? karyawan : karyawan.karyawan;

  if (!Array.isArray(karyawanList)) {
    return <div>Data format is incorrect</div>;
  }

  // Filter data based on the search term for name, branch, or NIK
  const filteredKaryawan = karyawanList.filter((karyawan) =>
    karyawan.nama_lengkap?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const deleteKaryawan = async (id) => {
    const userConfirm = window.confirm('Apakah anda ingin menghapus data?');
    if (userConfirm) {
      try {
        console.log('Deleting karyawan with id:', id);
        await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/karyawan/${id}`);
        console.log('Data berhasil dihapus');
        mutate();
      } catch (error) {
        console.error('Data gagal dihapus:', error.response ? error.response.data : error.message);
      }
    }
  };

  return (
    <div className="card is-shadowless">
      <div className="table-container">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Cari nama"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="input is-small search-input"
          />
        </div>
        <div className="card is-shadowless">
          <table className="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>No</th>
                <th>NIK</th>
                <th>Nama</th>
                <th>Jabatan</th>
                <th>Cabang</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredKaryawan.map((karyawan, index) => (
                <tr key={karyawan.id || index}>
                  <td>{index + 1}</td>
                  <td>{karyawan.nik}</td>
                  <td>{karyawan.nama_lengkap}</td>
                  <td>{karyawan.jabatan}</td>
                  <td>{karyawan.Cabang ? karyawan.Cabang.nama_cabang : 'N/A'}</td>
                  <td>
                    {karyawan.id ? (
                      <Link to={`/datakaryawan/edit/${karyawan.id}`} className="button is-small is-info">
                        Edit
                      </Link>
                    ) : (
                      <span className="button is-small is-info" disabled>
                        Edit
                      </span>
                    )}
                    <button onClick={() => deleteKaryawan(karyawan.id)} className="button is-small is-danger">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
