import React from 'react';
import useSWR from 'swr';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import { FaUsers, FaBuilding ,FaCalendarAlt} from 'react-icons/fa';
import AbsensiChart from './DataAbsensi';
import AbsensiDetail from './AbsensiDetail';
import DataAbsenHarian from './DataAbsenHarian';
import "../admin/css/dashboardAdmin.css";

const fetcher = url => axios.get(url, { withCredentials: true }).then(res => res.data);

const DashboardAdmin = () => {
  const { data: karyawanData, error: karyawanError } = useSWR(`${process.env.REACT_APP_API_BASE_URL}/karyawan`, fetcher);
  const { data: cabangData, error: cabangError } = useSWR(`${process.env.REACT_APP_API_BASE_URL}/cabang`, fetcher);
  const { data: absensiHariIniData, error: absensiHariIniError } = useSWR(`${process.env.REACT_APP_API_BASE_URL}/absensihari/get`, fetcher);

  const totalKaryawan = karyawanData?.totalKaryawan || 0;
  const totalCabang = cabangData?.length || 0;
  const totalAbsenHariIni = absensiHariIniData?.jumlahAbsensi || 0;

  if (karyawanError || cabangError || absensiHariIniError) {
    console.error('Error fetching data:', karyawanError || cabangError || absensiHariIniError);
    return <div>Error loading data...</div>;
  }

  const cardStyle = {
    borderRadius: '15px',
    padding: '20px',
    color: 'white',
    height: '150px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  };

  return (
    <div className="dashboard-admin">
      <div className="dashboard-row">
        <div className="dashboard-col">
          <Card style={{...cardStyle, background: 'linear-gradient(45deg, #FF9A8B 0%, #FF6A88 100%)'}} className="dashboard-card">
          {/* <Card style={{...cardStyle, background: '#FF9A8B'}} className="dashboard-card"> */}
            <div>
              <h3>Total Karyawan</h3>
              <h2>{totalKaryawan}</h2>
            </div>
            <FaUsers size={50} />
          </Card>
        </div>
        <div className="dashboard-col">
          <Card style={{...cardStyle, background: 'linear-gradient(45deg, #84fab0 0%, #8fd3f4 100%)'}} className="dashboard-card">
          {/* <Card style={{...cardStyle, background: '#84fab0'}} className="dashboard-card"> */}
            <div>
              <h3>Total Cabang</h3>
              <h2>{totalCabang}</h2>
            </div>
            <FaBuilding size={50} />
          </Card>
        </div>
        <div className="dashboard-col">
          <Card style={{...cardStyle, background: 'linear-gradient(45deg, #FF9A8B 0%, #8fd3f4 100%)'}} className="dashboard-card">
            <div>
              <h3>Total Kehadiran Hari Ini</h3>
              <h2>{totalAbsenHariIni}</h2>
            </div>
            <FaCalendarAlt size={50} />
          </Card>
        </div>
      </div>
      <div className="chart-section">
        <h2>Data Absensi Bulan Ini</h2>
        <AbsensiChart />
      </div>
      <div className="chart-section">
        <h2>Data Absensi Hari Ini</h2>
        <DataAbsenHarian />
      </div>
      <div className="chart-section">
        <h2>Data Absensi Bulan Ini</h2>
        <AbsensiDetail />
      </div>
    </div>
  );
};

export default DashboardAdmin;
