import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    user: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}

export const LoginUser = createAsyncThunk("user/loginUser", async(user, thunkAPI) => {
    try {
        // const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/loginKaryawan`, {
        //     nik: user.nik,
        //     password: user.password
        // });
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/loginKaryawan`, {
            nik: user.nik,
            password: user.password
        }, { withCredentials: true });
        return response.data;
    } catch (error) {
        if(error.response){
            const message = error.response.data.msg;
            return thunkAPI.rejectWithValue(message);
        }
    }
});
//validasi agar masuk ke dashboard harus login


// export const getMe = createAsyncThunk("user/getMe", async(_, thunkAPI) => {
//     try {
//         const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/MeKaryawan`);
//         return response.data;
//     } catch (error) {
//         if(error.response){
//             const message = error.response.data.msg;
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// });
export const getMe = createAsyncThunk("user/getMe", async(_, thunkAPI) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/MeKaryawan`, { withCredentials: true });
        return response.data;
    } catch (error) {
        if(error.response){
            const message = error.response.data.msg;
            return thunkAPI.rejectWithValue(message);
        }
    }
});

export const LogOut = createAsyncThunk("user/logOut", async() => {
    await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/logoutKaryawan`);
});

export const authKaryawanSlice = createSlice({
    name: "authKaryawan", // Perbaiki nama slice
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(LoginUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(LoginUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
        });
        builder.addCase(LoginUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        });

        builder.addCase(getMe.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMe.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
        });
        builder.addCase(getMe.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        });
    }
});

export const { reset } = authKaryawanSlice.actions;
export default authKaryawanSlice.reducer;