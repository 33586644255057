import React, { useState } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { Table, Modal, Button, Row, Col } from 'react-bootstrap'; 
import "../admin/css/dataabsenharian.css";
import Lokasi from './lokasi';

axios.defaults.withCredentials = true;

const fetcher = (url) => axios.get(url).then(res => res.data);

const DataAbsenHarian = () => {
  const { data, error } = useSWR(`${process.env.REACT_APP_API_BASE_URL}/absensihari/get`, fetcher);
  const [showModal, setShowModal] = useState(false);
  const [selectedAbsen, setSelectedAbsen] = useState(null);

  if (error) return <div>Error loading data...</div>;
  if (!data) return <div>Loading...</div>;

  const { absensiHarian } = data;

  const sortedAbsensiHarian = absensiHarian.sort((a, b) => {
    const cabangA = a.karyawan.Cabang.nama_cabang.toLowerCase();
    const cabangB = b.karyawan.Cabang.nama_cabang.toLowerCase();
    return cabangA.localeCompare(cabangB);
  });

  const handleRowClick = (absen) => {
    setSelectedAbsen(absen);
    setShowModal(true);
  };

  return (
    <>
      <div className="table-responsive">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>No</th>
              <th>Nama Karyawan</th>
              <th>Nama Cabang</th>
              <th>Tanggal Absensi</th>
              <th>Jam Masuk</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            {sortedAbsensiHarian.map((absen, index) => (
              <tr key={absen.id} onClick={() => handleRowClick(absen)}>
                <td>{index + 1}</td>
                <td>{absen.karyawan.nama_lengkap}</td>
                <td>{absen.karyawan.Cabang.nama_cabang}</td>
                {/* <td>{absen.tgl_absensi}</td> */}
                <td>{new Date(absen.tgl_absensi).toLocaleDateString('id-ID', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                <td>{absen.jam_masuk}</td>
                <td><Button onClick={() => handleRowClick(absen)}>Lihat Detail</Button></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal 
        show={showModal} 
        onHide={() => setShowModal(false)} 
        centered
        style={{ 
          backgroundColor: 'rgba(0, 0, 0, 0)', 
          backdropFilter: 'blur(15px)'
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detail Absensi</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          {selectedAbsen && (
            <>
              <Row>
                <Col md={6}>
                  <p><strong>Nama Karyawan:</strong> {selectedAbsen.karyawan.nama_lengkap}</p>
                  <p><strong>Nama Cabang:</strong> {selectedAbsen.karyawan.Cabang.nama_cabang}</p>
                  {/* <p><strong>Tanggal Absensi:</strong> {selectedAbsen.tgl_absensi}</p> */}
                  <p><strong>Tanggal Absensi:</strong> {new Date(selectedAbsen.tgl_absensi).toLocaleDateString('id-ID', { day: '2-digit', month: '2-digit', year: 'numeric' })}</p>
                  <p><strong>Jam Masuk:</strong> {selectedAbsen.jam_masuk}</p>
                  <p><strong>Foto Masuk:</strong></p>
                  {selectedAbsen.foto_masuk ? (
                    <img 
                      // src={`http://localhost:5000/uploads/absensi/${selectedAbsen.foto_masuk}`} 
                      src={`${process.env.REACT_APP_API_BASE_URL}/uploads/absensi/${selectedAbsen.foto_masuk}`} 
                      alt="Foto Masuk" 
                      width="50%" 
                      style={{ borderRadius: '5px', marginBottom: '10px' }} 
                    />
                  ) : (
                    <p>Foto Masuk tidak tersedia</p>
                  )}
                </Col>
                <Col md={6}>
                  <p><strong>Lokasi Masuk:</strong></p>
                  {selectedAbsen.lokasi_masuk && (
                    <Lokasi
                      latitude={parseFloat(selectedAbsen.lokasi_masuk.split(',')[0])}
                      longitude={parseFloat(selectedAbsen.lokasi_masuk.split(',')[1])}
                      style={{ width: '300px', height: '300px', borderRadius: '5px', marginBottom: '10px' }}  // Set ukuran peta disini
                    />
                  )}
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#f8f9fa' }}>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DataAbsenHarian;