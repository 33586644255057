import React, { useState } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import jsPDF from 'jspdf';
import "jspdf-autotable";

axios.defaults.withCredentials = true;

const fetcher = (url) => axios.get(url).then(res => res.data);

const DataGajiCabang = () => {
    const [bulan, setBulan] = useState('');
    const [tahun, setTahun] = useState('');
    const [fetchData, setFetchData] = useState(false);
    const [warning, setWarning] = useState('');
    //const scrollRef = useRef(null);

    const { data: gaji, error } = useSWR(
        fetchData ? `${process.env.REACT_APP_API_BASE_URL}/getgajibycabang?bulan=${bulan}&tahun=${tahun}` : null,
        fetcher
    );

    const handleMonthChange = (e) => {
        const [year, month] = e.target.value.split("-");
        setBulan(month);
        setTahun(year);
    };

    const handleTampilkanData = () => {
        if (!bulan || !tahun) {
            setWarning('Silahkan pilih bulan dan tahun.');
        } else {
            setWarning('');
            setFetchData(true);
        }
    };

    const handleExportPDF = (cabangId) => {
        const cabang = gaji[cabangId];
        const doc = new jsPDF({ orientation: 'landscape' });

        // Judul di bagian atas
        doc.text(`Data Gaji ${cabang.cabang.nama_cabang} - ${bulan}/${tahun}`, 14, 10);

        // Header tabel
        const tableColumn = ["Nama", "Periode", "Nominal", "Tambahan", "Potongan", "Jumlah", "Keterangan", "Status"];
        const tableRows = [];

        cabang.gaji.forEach((item) => {
            const rowData = [
                item.Karyawan.nama_lengkap,
                item.periode,
                item.nominal,
                item.tambahan,
                item.potongan,
                item.jumlah,
                item.keterangan,
                item.status
            ];
            tableRows.push(rowData);
        });

        // Generate tabel di PDF
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 20,
            theme: 'grid',
            styles: { fontSize: 8 },
        });

        // Save file PDF
        doc.save(`gaji-${cabang.cabang.nama_cabang}-${bulan}-${tahun}.pdf`);
    };

    return (
        <div className="card is-shadowless">
            <form onSubmit={(e) => e.preventDefault()}>
                <label>
                    Bulan:
                    <input
                        type="month"
                        value={`${tahun}-${bulan}`}
                        onChange={handleMonthChange}
                        placeholder="YYYY-MM"
                    />
                </label>
                <button type="button" onClick={handleTampilkanData}>
                    Tampilkan Data
                </button>
            </form>

            {warning && <div style={{ color: 'red', marginTop: '10px' }}>{warning}</div>}

            {fetchData && error && error.response?.status === 404 && (
                <div>Data kosong untuk bulan dan tahun yang dipilih.</div>
            )}

            {gaji && Object.keys(gaji).map((key) => {
                const cabang = gaji[key];
                return (
                    <div key={key} className="card is-shadowless">
                        <h3>{cabang.cabang.nama_cabang}</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>Nama</th>
                                    <th>Periode</th>
                                    <th>Nominal</th>
                                    <th>Tambahan</th>
                                    <th>Potongan</th>
                                    <th>Jumlah</th>
                                    <th>Keterangan</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cabang.gaji.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.Karyawan.nama_lengkap}</td>
                                        <td>{item.periode}</td>
                                        <td>{item.nominal}</td>
                                        <td>{item.tambahan}</td>
                                        <td>{item.potongan}</td>
                                        <td>{item.jumlah}</td>
                                        <td>{item.keterangan}</td>
                                        <td>{item.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button onClick={() => handleExportPDF(key)}>Export to PDF</button>
                    </div>
                );
            })}
        </div>
    );
};

export default DataGajiCabang;
