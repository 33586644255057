import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

export const FormEditKaryawan = () => {
  const [namaLengkap, setNamaLengkap] = useState('');
  const [nik, setNik] = useState('');
  const [jabatan, setJabatan] = useState('');
  const [noTelp, setNoTelp] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [password, setPassword] = useState('');
  const [cabangId, setCabangId] = useState('');
  const [cabangs, setCabangs] = useState([]);
  const [message, setMessage] = useState('');
  const [preview, setPreview] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getKaryawanById = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/karyawan/${id}`,{withCredentials: true});
            console.log('Karyawan:', response.data);  // Cek respons API di sini
            setNamaLengkap(response.data.nama_lengkap);
            setNik(response.data.nik);
            setJabatan(response.data.jabatan);
            setNoTelp(response.data.no_telp);  // Cek apakah no_telp ini benar ada
            setPassword('');
            setCabangId(response.data.CabangId);  // Pastikan ini yang benar
            setAvatar(response.data.avatar);
            if (response.data.avatar) {
                setPreview(`${process.env.REACT_APP_API_BASE_URL}/uploads/karyawan/${response.data.avatar}`,{withCredentials:true});
            }
        } catch (error) {
            setMessage(error.response?.data?.msg || 'Error fetching data');
        }
    };
    getKaryawanById();
}, [id]);

useEffect(() => {
    const fetchCabangs = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/cabang`,{withCredentials:true});
            console.log('Cabangs:', response.data);  // Cek apakah cabang data ter-fetch dengan benar
            setCabangs(response.data);
        } catch (error) {
            console.error(error);
        }
    };
    fetchCabangs();
}, []);


  const updateKaryawan = async (e) => {
    e.preventDefault();
    try {
        const formData = new FormData();
        formData.append('nik', nik);
        formData.append('nama_lengkap', namaLengkap);
        formData.append('jabatan', jabatan);
        formData.append('no_telp', noTelp);
        formData.append('password', password);
        formData.append('CabangId', cabangId);

        if (avatar) {
            formData.append('file', avatar);
        }

        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/karyawan/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        },{withCredentials:true});
        navigate('/datakaryawan');
    } catch (error) {
        setMessage(error.response?.data?.msg || 'Error updating data');
    }
};

const loadImage = (e) => {
    const image = e.target.files[0];
    setAvatar(image);
    setPreview(URL.createObjectURL(image));
};

  return (
    <div className="columns">
      <div className="column is-three-quarters">
        <div className="card is-shadowless">
          <div className="card-content">
            <h2 className="subtitle">Edit Karyawan</h2>
            <div className="content">
              <form onSubmit={updateKaryawan}>
                <p className='has-text-centered'>{message}</p>
                <div className="field">
                  <label className="label">NIK</label>
                  <div className="control">
                    <input
                      type="number"
                      className="input"
                      value={nik}
                      onChange={(e) => setNik(e.target.value)}
                      placeholder="NIK"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Nama Karyawan</label>
                  <div className="control">
                    <input
                      type="text"
                      className="input"
                      value={namaLengkap}
                      onChange={(e) => setNamaLengkap(e.target.value)}
                      placeholder="Nama Lengkap"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Jabatan</label>
                  <div className="control">
                    <input
                      type="text"
                      className="input"
                      value={jabatan}
                      onChange={(e) => setJabatan(e.target.value)}
                      placeholder="Jabatan"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Cabang Saat Ini</label>
                  <div className="control">
                    <input
                      type="text"
                      className="input"
                      value={cabangs.find(cabang => cabang.id === cabangId)?.nama_cabang || "Cabang tidak ditemukan"}
                      readOnly
                      placeholder="Cabang Saat Ini"
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Pilih Cabang Baru</label>
                  <div className="control">
                    <select value={cabangId} onChange={(e) => setCabangId(e.target.value)}>
                      <option value="">Pilih Cabang</option>
                      {cabangs.map((cabang) => (
                        <option key={cabang.id} value={cabang.id}>{cabang.nama_cabang}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="field">
                  <label className="label">Nomor Telepon</label>
                  <div className="control">
                    <input
                      type="number"
                      className="input"
                      value={noTelp}
                      onChange={(e) => setNoTelp(e.target.value)}
                      placeholder="No Telpon"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Password</label>
                  <div className="control">
                    <input
                      type="password"
                      className="input"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="********"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Profile</label>
                  <div className="control">
                    <input type="file" accept="image/*" onChange={loadImage} />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    {preview && (
                      <figure className="image is-128x128">
                        <img src={preview} alt="Preview" />
                      </figure>
                    )}
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <button type="submit" className="button is-success">Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="column">
  <div className="card" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', overflow: 'hidden' }}>
    <div className="card-image" style={{ backgroundColor: '#f5f5f5', padding: '20px', textAlign: 'center' }}>
      <figure className="image is-128x128" style={{ margin: '0 auto' }}>
        <img 
          src={preview || `${process.env.REACT_APP_API_BASE_URL}/uploads/karyawan/${avatar}`} 
          alt="Karyawan Avatar" 
          style={{ borderRadius: '50%' }} 
        />
      </figure>
    </div>
    <div className="card-content" style={{ padding: '20px' }}>
      <p className="subtitle is-6" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Nama: {namaLengkap}</p>
      <p className="subtitle is-6" style={{ marginBottom: '10px' }}>NIK: {nik}</p>
      <p style={{ marginBottom: '10px', color: '#4a4a4a' }}>Jabatan: {jabatan}</p>
      <p style={{ marginBottom: '10px', color: '#4a4a4a' }}>Cabang: {cabangs.find(cabang => cabang.id === cabangId)?.nama_cabang || "Cabang tidak ditemukan"}</p>
      <p style={{ marginBottom: '10px', color: '#4a4a4a' }}>No. Telp: {noTelp}</p>
    </div>
  </div>
</div>

    </div>
  );
};
