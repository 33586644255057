import React, { useState } from 'react';
import useSWR from 'swr';
import axios from 'axios';
import styled from 'styled-components';
import { Select, MenuItem ,FormControl, InputLabel,TableCell} from '@mui/material';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';

axios.defaults.withCredentials = true;
const fetcher = (url) => axios.get(url).then(res => res.data);
const getYears = (yearsCount) => {
  const currentYear = new Date().getFullYear();
  return [...Array(yearsCount)].map((_, index) => currentYear + index);
};

const TableContainer = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  overflow-x: auto; 
`;
// const Select = styled.select`
//   padding: 10px;
//   margin: 10px;
//   border-radius: 8px;
//   border: 1px solid #ced4da;
//   background-color: #f8f9fa;
//   font-size: 16px;
//   color: #495057;
//   width: 150px;
//   &:focus {
//     border-color: #80bdff;
//     box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
//     outline: none;
//   }
// `;


const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 600px; 
`;

const TableHeader = styled.th`
  background-color: #f8f9fa;
  color: #495057;
  font-weight: 600;
  padding: 12px;
  text-align: left;
  border-bottom: 2px solid #dee2e6;
  cursor: pointer; 
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f8f9fa;
  }
  &:hover {
    background-color: #e9ecef;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin-bottom: 20px;
  &:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }
`;

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    margin: 0 5px;
    padding: 10px 15px;
    border: 1px solid #ced4da;
    background-color: #f8f9fa;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
`;

const AbsenControll = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  

  const { data, error, mutate } = useSWR(`${process.env.REACT_APP_API_BASE_URL}/absensiall/get`, fetcher, {
    refreshInterval: 3000 
  });

  if (error) return <div>Silahkan refresh</div>;
  if (!data) return <div>Loading...</div>;

  const filteredData = data.filter(absensi => {
    const absensiDate = new Date(absensi.tgl_absensi);
    const matchesSearch = 
      (absensi.karyawan.nama_lengkap && absensi.karyawan.nama_lengkap.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (absensi.tgl_absensi && absensi.tgl_absensi.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (absensi.jam_masuk && absensi.jam_masuk.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (absensi.jam_keluar && absensi.jam_keluar.toLowerCase().includes(searchTerm.toLowerCase()));

    const matchesMonthYear = 
      (!selectedMonth || absensiDate.getMonth() + 1 === parseInt(selectedMonth)) &&
      (!selectedYear || absensiDate.getFullYear() === parseInt(selectedYear));

    return matchesSearch && matchesMonthYear;
  });

  const sortedData = filteredData.sort((a, b) => {
    const dateA = new Date(a.tgl_absensi);
    const dateB = new Date(b.tgl_absensi);
    return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
  });

  const itemsPerPage = 25;
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);
  const currentPageData = sortedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const deleteAbsensi = async (id) => {
    const userConfirm = window.confirm('Apakah anda ingin menghapus absensi?');

    if (userConfirm) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/absensi/${id}`,{withCredentials:true});
        console.log('Absensi berhasil dihapus');
        mutate(); 
      } catch (error) {
        console.error('Absensi gagal dihapus:', error);
      }
    }
  };

  const handleSort = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };


  
  // const ButtonContainer = () => {
  //   const [selectedYear, setSelectedYear] = useState('');

  return (
    <TableContainer>
      <SearchInput 
        type="text" 
        placeholder="Cari karyawan, tanggal, atau absensi..." 
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
       <FormControl variant="outlined" style={{ marginRight: '10px', minWidth: 80 , minHeight: 20}}>
  <InputLabel>Bulan</InputLabel>
  <Select
    value={selectedMonth}
    onChange={(e) => setSelectedMonth(e.target.value)}
    label="Bulan"
  >
    <MenuItem value="">Pilih Bulan</MenuItem>
    {[...Array(12)].map((_, index) => (
      <MenuItem key={index + 1} value={index + 1}>{index + 1}</MenuItem>
    ))}
  </Select>
</FormControl>

<FormControl variant="outlined" style={{ minWidth: 80 ,height:  20 }}>

  <InputLabel>Tahun</InputLabel>
  <Select
    value={selectedYear}
    onChange={(e) => setSelectedYear(e.target.value)}
    label="Tahun"
  >
    <MenuItem value="">
      <em>Pilih Tahun</em>
    </MenuItem>
    {getYears(10).map((year) => (
      <MenuItem key={year} value={year}>{year}</MenuItem>
    ))}
  </Select>
</FormControl>
      <StyledTable>
        <thead>
          <tr>
            <TableHeader>No</TableHeader>
            <TableHeader>Nama Karyawan</TableHeader>
            <TableHeader onClick={handleSort}>
              Tanggal {sortDirection === 'asc' ? '▲' : '▼'}
            </TableHeader>
            <TableHeader>Jam Masuk</TableHeader>
            <TableHeader>Jam Keluar</TableHeader>
            <TableHeader>Aksi</TableHeader>
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((absensi, index) => {
            const tanggal = absensi.tgl_absensi
            ? new Date(absensi.tgl_absensi).toLocaleDateString('id-ID', { day: '2-digit', month: '2-digit', year: 'numeric' })
            : '';
            return (
            <TableRow key={absensi.id}>
              <TableCell>{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
              <TableCell>{absensi.karyawan.nama_lengkap}</TableCell>
              <TableCell>{tanggal}</TableCell>
              <TableCell>{absensi.jam_masuk}</TableCell>
              <TableCell>{absensi.jam_keluar || 'Belum keluar'}</TableCell>
              <TableCell>
                <Link to={`/dataabsen/edit/${absensi.id}`} className="button is-small is-info">
                  <FaEdit />
                </Link>
                <button onClick={() => deleteAbsensi(absensi.id)}  className="button is-small is-danger">
                  <FaTrashAlt />
                </button>
              </TableCell>
            </TableRow>
            );
          })}
        </tbody>
      </StyledTable>
      <PaginationControls>
        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      </PaginationControls>
    </TableContainer>
  );
};

export default AbsenControll;
