import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const FormEditProfile = () => {
    const [profile, setProfile] = useState({});
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Me`,{withCredentials: true});
                const profileData = response.data;
                setProfile(profileData);
                setName(profileData.name);
                setEmail(profileData.email);
            } catch (error) {
                setMessage(error.message);
            }
        };

        fetchProfile();
    }, []);

    const updateProfile = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/users/${profile.id}`, {
                name: name,
                email: email,
                password: password,
                confPassword: confPassword
            },{withCredentials:true});
            
            console.log(response.data);
            navigate('/admin');
        } catch (error) {
            setMessage(error.response?.data?.msg || 'Terjadi kesalahan saat mengupdate profil.');
        }
    };

    return (
        <div>
            <div className="card is-shadowless">
                <p className="subtitle">Edit Profile</p>
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={updateProfile}>
                            <p className="has-text-centered">{message}</p>
                            <div className="field">
                                <label className="label">Nama</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Silahkan ganti nama"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Email</label>
                                <div className="control">
                                    <input
                                        type="email"
                                        className="input"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Edit Email"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Password</label>
                                <div className="control">
                                    <input
                                        type="password"
                                        className="input"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="******"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Confirm Password</label>
                                <div className="control">
                                    <input
                                        type="password"
                                        className="input"
                                        value={confPassword}
                                        onChange={(e) => setConfPassword(e.target.value)}
                                        placeholder="******"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-success">
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
