import React from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

axios.defaults.withCredentials = true;

const fetcher = (url) => axios.get(url).then(res => res.data);

const AbsensiChart = () => {
  const now = new Date();
  const bulan = now.getMonth() + 1;
  const tahun = now.getFullYear();

  const { data, error } = useSWR(`${process.env.REACT_APP_API_BASE_URL}/absensitotal/get?bulan=${bulan}&tahun=${tahun}`, fetcher);

  if (error) return <div>Silahkan refresh</div>;
  if (!data) return <div>Loading...</div>;

  const chartData = Object.keys(data).map(karyawanId => ({
    name: data[karyawanId].nama_lengkap,
    kehadiran: data[karyawanId].kehadiran
  }));

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <ResponsiveContainer>
        <LineChart
          data={chartData}
          margin={{ top: 20, right: 30, left: 20, bottom: 50 }} // Increase bottom margin for better spacing
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="name" 
            angle={-45} // Rotate labels
            textAnchor="end" // Align labels
            height={80} // Increase height for better readability
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="kehadiran" stroke="#8884d8" activeDot={{ r: 8 }}>
            <LabelList dataKey="kehadiran" position="top" /> {/* Show labels on top */}
          </Line>
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AbsensiChart;
