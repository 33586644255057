import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';

import { Container, Card, CardContent, Typography, Grid, Button, Modal, Box, CircularProgress, Alert } from '@mui/material';

export const ConfirmGaji = () => {
    const navigate = useNavigate();
    const [gajiList, setGajiList] = useState([]);
    const [selectedGaji, setSelectedGaji] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Menambahkan state loading
    const [open, setOpen] = useState(false); // Untuk modal

    useEffect(() => {
        const getGaji = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/getgaji`, { withCredentials: true });
                setGajiList(response.data);
                setLoading(false); // Set loading false setelah data diambil
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    console.error("Unauthorized access. Redirecting to login...");
                    navigate('/login'); // Redirect ke halaman login jika 401
                } else {
                    console.error("Gagal mengambil slip gaji:", error); // Log kesalahan
                    setError("Gagal mengambil slip gaji. Silakan coba lagi nanti."); // Pesan kesalahan untuk pengguna
                }
                setLoading(false); // Set loading false setelah error
            }
        };
        getGaji();
    
    }, []);

    const handleOpenModal = async (id) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/getgajibyid/${id}`, { withCredentials: true });
            setSelectedGaji(response.data);
            setOpen(true); // Buka modal
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error("Unauthorized access. Redirecting to login...");
                navigate('/login'); // Redirect ke halaman login jika 401
            } else {
                console.error("Gagal mengambil detail slip gaji:", error); // Log kesalahan
                setError("Gagal mengambil detail slip gaji. Silakan coba lagi nanti."); // Pesan kesalahan untuk pengguna
            }
        }
    };
    const handleCloseModal = () => setOpen(false);

    const confirmGaji = async () => {
        if (!selectedGaji) return;
    
        // Tutup modal sebelum SweetAlert muncul
        setOpen(false);
    
        try {
            // SweetAlert Confirmation
            const result = await Swal.fire({
                title: 'Konfirmasi Terima Gaji',
                text: 'Apakah Anda yakin ingin menerima slip gaji ini?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya, Terima',
                cancelButtonText: 'Batal',
                position: 'top'
            });
    
            if (result.isConfirmed) {
                await axios.put(`${process.env.REACT_APP_API_BASE_URL}/updategajibykaryawan/${selectedGaji.id}`, {
                    status: 'diterima'
                }, { withCredentials: true });
    
                // SweetAlert Success Notification
                await Swal.fire({
                    title: 'Berhasil!',
                    text: 'Slip gaji telah diterima.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    position: 'top'
                });
    
                // Update status atau lakukan apa pun yang dibutuhkan setelah konfirmasi sukses
            }
        } catch (error) {
            console.error("Gagal memperbarui status gaji:", error);
    
            // SweetAlert Error Notification
            Swal.fire({
                title: 'Gagal!',
                text: 'Gagal memperbarui status gaji. Silakan coba lagi nanti.',
                icon: 'error',
                confirmButtonText: 'OK',
                position: 'top'
            });
        }
    };

    const downloadPDF = () => {
        if (!selectedGaji) return;

        const doc = new jsPDF();

        doc.text(`Detail Slip Gaji`, 10, 10);
        doc.text(`Nama: ${selectedGaji.Karyawan?.nama_lengkap || 'Data tidak tersedia'}`, 10, 20);
        doc.text(`Cabang: ${selectedGaji.Karyawan?.Cabang?.nama_cabang || 'Data tidak tersedia'}`, 10, 30);
        doc.text(`Nominal: ${selectedGaji.nominal}`, 10, 40);
        doc.text(`Tambahan: ${selectedGaji.tambahan}`, 10, 50);
        doc.text(`Potongan: ${selectedGaji.potongan}`, 10, 60);
        doc.text(`Jumlah: ${selectedGaji.jumlah}`, 10, 70);
        doc.text(`Status: ${selectedGaji.status}`, 10, 80);

        doc.save(`Slip_Gaji_${selectedGaji.Karyawan?.nama_lengkap || 'Data'}_${new Date().toLocaleDateString()}.pdf`);
    };

    if (loading) {
        return <CircularProgress />; // Menampilkan indikator loading saat data sedang diambil
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>; // Menampilkan pesan kesalahan
    }

    return (
        <Container>
            <Typography variant="p" gutterBottom>Daftar Slip Gaji</Typography>
            {gajiList.length > 0 ? (
                gajiList.map((gaji) => (
                    <Card key={gaji.id} style={{ marginBottom: '20px' }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Nama:</strong>
                                        <span>{gaji.Karyawan?.nama_lengkap || 'Data tidak tersedia'}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Periode:</strong>
                                        <span>{gaji.periode}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Tanggal:</strong>
                                        <span>{gaji.tanggal_periode}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Jumlah:</strong>
                                        <span>{gaji.jumlah}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Status:</strong>
                                        <span>{gaji.status}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenModal(gaji.id)}
                        style={{ padding: '6px 12px', fontSize: '0.875rem' }}
                        >
                        Lihat Detail & Konfirmasi
                        </Button>
                        </CardContent>
                    </Card>
                ))
            ) : (
                <Typography>Data gaji tidak ditemukan.</Typography>
            )}

            <Modal open={open} onClose={handleCloseModal}>
                <Box style={{ backgroundColor: 'white', padding: '20px', margin: 'auto', marginTop: '10%', maxWidth: '500px' }}>
                    {selectedGaji ? (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" style={{ marginBottom: '20px' }}>Detail Slip Gaji</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Nama:</strong>
                                        <span>{selectedGaji.Karyawan?.nama_lengkap || 'Data tidak tersedia'}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Periode:</strong>
                                        <span>{selectedGaji.periode}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Tanggal:</strong>
                                        <span>{selectedGaji.tanggal_periode}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Cabang:</strong>
                                        <span>{selectedGaji.Karyawan?.Cabang?.nama_cabang || 'Data tidak tersedia'}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Nominal:</strong>
                                        <span>{selectedGaji.nominal}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Tambahan:</strong>
                                        <span>{selectedGaji.tambahan}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Potongan:</strong>
                                        <span>{selectedGaji.potongan}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Total:</strong>
                                        <span>{selectedGaji.jumlah}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" onClick={confirmGaji}>Terima Gaji</Button>
                                    <Button variant="outlined" color="secondary" onClick={downloadPDF} style={{ marginLeft: '10px' }}>Download PDF</Button>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Typography>Data tidak tersedia.</Typography>
                    )}
                </Box>
            </Modal>
            <Button component={NavLink} to="/dashboard" variant="outlined" fullWidth>
                Kembali
            </Button>
        </Container>
    );
};
