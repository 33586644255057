import React, { useState, useEffect } from 'react';
import { Container, Card, CardContent, Typography, Grid, Avatar, Button, Box } from '@mui/material';
import { IoLogOut, IoCalendarOutline, IoPricetag, IoPersonOutline, IoWalletOutline } from "react-icons/io5";
import axios from 'axios';
import { NavLink, useNavigate } from "react-router-dom";
import '../app/dashboardKaryawan.css';
import { LogOut, reset } from "../fitur/AuthKaryawan";
import { useDispatch } from "react-redux";

export const Dashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dataKaryawan, setDataKaryawan] = useState(null);
    const [error, setError] = useState(null);
    const [currentTime, setCurrentTime] = useState('');
    const [absensiData, setAbsensiData] = useState(null);
    const [absensiBulan, setAbsensiBulan] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const formattedDate = now.toLocaleDateString('en-US', options);
            const formattedTime = now.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
            setCurrentTime(`${formattedDate} - ${formattedTime}`);
        }, 1000);

        return () => clearInterval(interval);
    }, []);
 
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/MeKaryawan`, { withCredentials: true });
                setDataKaryawan(response.data);
            } catch (error) {
                setError(error.message);
            }
        };
    
        const fetchAbsenBulan = async () => {
            try {
                const now = new Date();
                const bulan = now.getMonth() + 1;
                const tahun = now.getFullYear();
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/absensikaryawan/get?bulan=${bulan}&tahun=${tahun}`, { withCredentials: true });
                setAbsensiBulan(response.data);
            } catch (error) {
                setError(error.message);
            }
        };
    
        const fetchAbsensiHariIni = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/absensi/get`, { withCredentials: true });
                setAbsensiData(response.data);
            } catch (error) {
                setError(error.message);
            }
        };
    
        fetchProfile();
        fetchAbsensiHariIni();
        fetchAbsenBulan();
    }, []);
    

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!dataKaryawan || !absensiData ) {
        return <div>Loading...</div>;
    }
    const totalAbsenBulanIni = absensiBulan ? absensiBulan.length : 0;
    const { absensiHariIni } = absensiData;
    const logout = () => {
        dispatch(LogOut());
        dispatch(reset());
        navigate("/");
    };

    return (
        <Container maxWidth="sm" style={{ marginTop: '20px' }}>
            <Card>
                <CardContent style={{ position: 'relative' }}>
                    <Button 
                        onClick={logout} 
                        variant="outlined" 
                        startIcon={<IoLogOut />} 
                        style={{ position: 'absolute', top: 0, right: 0 }}
                    >
                        
                    </Button>
                    {dataKaryawan ? (
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3}>
                                <Avatar 
                                    alt={dataKaryawan.nama_lengkap} 
                                    src={dataKaryawan.url} 
                                    sx={{ width: 80, height: 100 }} 
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">{dataKaryawan.nama_lengkap}</Typography>
                                <Typography variant="body2">{dataKaryawan.jabatan}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <div>Loading...</div>
                    )}
                    
                  
                     <Box sx={{ backgroundColor: '#f44336', padding: '10px', borderRadius: '10px' }}>
                        <Typography variant="body1" style={{ color: '#fff' }}>
                            Jangan Lupa Berdoa Sebelum melakukan aktivitas
                        </Typography>
                        <Typography variant="body2" style={{ color: '#fff' }}>
                        {currentTime}
                        </Typography>
                        <Box sx={{ backgroundColor: '#f44336', padding: '10px', borderRadius: '10px' }}>
    <Grid container spacing={2} style={{ marginTop: '10px' }}>
        <Grid item xs={6}>
            <Button 
                component={NavLink} 
                to="/createAbsen" 
                variant="contained" 
                fullWidth 
                style={{ backgroundColor: '#fff', color: '#000' }}
            >
                {absensiHariIni && absensiHariIni.jam_masuk ? absensiHariIni.jam_masuk : 'Masuk'}
            </Button>
        </Grid>
        <Grid item xs={6}>
            <Button 
                component={NavLink} 
                to="/clockout" 
                variant="contained" 
                fullWidth 
                style={{ backgroundColor: '#fff', color: '#000' }}
            >
                {absensiHariIni && absensiHariIni.jam_keluar ? absensiHariIni.jam_keluar : 'Pulang'}
            </Button>
        </Grid>
    </Grid>
</Box>


                    </Box>
                    <Grid container spacing={2} style={{ marginTop: '20px' }}>
                    <Grid item xs={3}>
                        Gaji
                        <Button component={NavLink} to="/confrimgaji" variant="outlined" fullWidth startIcon={<IoWalletOutline />}>
                            
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        Histori
                        <Button component={NavLink} to="/GetAbsen" variant="outlined" fullWidth startIcon={<IoCalendarOutline />}>
                            
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        Profile
                        <Button component={NavLink} to="/users" variant="outlined" fullWidth startIcon={<IoPersonOutline />}>
                            
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                    Detail
                        <Button component={NavLink} to="/GetAbsenBulan" variant="outlined" fullWidth startIcon={<IoPricetag />}>
                            
                        </Button>
                    </Grid>
                </Grid>
                    <Grid container spacing={2} style={{ marginTop: '20px' }}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="body2">Absensi Bulan Ini</Typography>
                                    <Typography variant="h6">{totalAbsenBulanIni} Kehadiran</Typography>
                                    {absensiBulan ? (
                                        <ul>
                                            {/* {absensiBulan.map((absen, index) => (
                                                <li key={index}>
                                                    {absen.tgl_absensi}: {absen.jam_masuk} - {absen.jam_keluar}
                                                </li>
                                            ))} */}
                                        </ul>
                                    ) : (
                                        <Typography variant="body2">Tidak ada data absensi untuk bulan ini.</Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
}

export default Dashboard;
