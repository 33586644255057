import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import "../admin/css/gaji.css"; // Your custom styles (if any)

export const FormEditGaji = () => {
    const [tanggal_periode, setTanggalPeriode] = useState('');
    const [periode, setPeriode] = useState('');
    const [nominal, setNominal] = useState('');
    const [tambahan, setTambahan] = useState('');
    const [potongan, setPotongan] = useState('');
    const [keterangan, setKeterangan] = useState('');
    const [id, setKaryawan] = useState('');
    const [karyawans, setKaryawans] = useState([]);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { id: gajiId } = useParams();

    useEffect(() => {
        const fetchKaryawan = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/karyawan`, { withCredentials: true });
                setKaryawans(response.data.karyawan || []);
            } catch (error) {
                setError('Gagal mengambil data karyawan.');
            }
        };
        fetchKaryawan();
    }, []);

    useEffect(() => {
        const fetchGaji = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/getgajibyidadmin/${gajiId}`, { withCredentials: true });
                const gaji = response.data;
                setTanggalPeriode(gaji.tanggal_periode);
                setPeriode(gaji.periode);
                setNominal(gaji.nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
                setTambahan(gaji.tambahan.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
                setPotongan(gaji.potongan.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
                setKeterangan(gaji.keterangan);
                setKaryawan(gaji.KaryawanId);
            } catch (error) {
                setError('Gagal memuat data gaji.');
            }
        };
        fetchGaji();
    }, [gajiId]);

    const handleNominalChange = (e) => {
        const value = e.target.value.replace(/\./g, '');
        setNominal(value.replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
    };

    const handleTambahanChange = (e) => {
        const value = e.target.value.replace(/\./g, '');
        setTambahan(value.replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
    };

    const handlePotonganChange = (e) => {
        const value = e.target.value.replace(/\./g, '');
        setPotongan(value.replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
    };

    const updateGaji = async (e) => {
        e.preventDefault();
        const nominalNumber = parseFloat(nominal.replace(/\./g, ''));  
        const tambahanNumber = parseFloat(tambahan.replace(/\./g, ''));
        const potonganNumber = parseFloat(potongan.replace(/\./g, ''));

        const data = {
            tanggal_periode,
            periode,
            nominal: nominalNumber,
            tambahan: tambahanNumber,
            potongan: potonganNumber,
            keterangan,
            KaryawanId: id
        };

        try {
            await axios.put(`${process.env.REACT_APP_API_BASE_URL}/updategajibyadmin/${gajiId}`, data, { withCredentials: true });
            setMessage('Gaji berhasil diupdate.');
            navigate('/datagaji');
        } catch (error) {
            setError(error.response?.data?.msg || 'Terjadi kesalahan.');
        }
    };

    return (
        <div className="columns is-centered mt-5">
            <div className="column is-half">
                <div className="card">
                    <div className="card-content">
                        <h2 className="subtitle has-text-centered">Edit Gaji</h2>
                        {message && <div className="notification is-success">{message}</div>}
                        {error && <div className="notification is-danger">{error}</div>}

                        <form onSubmit={updateGaji}>
                            <div className="field">
                                <label className="label">Pilih Karyawan</label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select value={id} onChange={(e) => setKaryawan(e.target.value)} required>
                                            <option value="">Pilih Karyawan</option>
                                            {karyawans.map((karyawan) => (
                                                <option key={karyawan.id} value={karyawan.id}>
                                                    {karyawan.nama_lengkap}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Tanggal Periode</label>
                                        <div className="control">
                                            <input
                                                type="date"
                                                className="input"
                                                value={tanggal_periode}
                                                onChange={(e) => setTanggalPeriode(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="field">
                                        <label className="label">Periode</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select value={periode} onChange={(e) => setPeriode(e.target.value)} required>
                                                    <option value="">Pilih Periode</option>
                                                    {["januari", "februari", "maret", "april", "mei", "juni", "juli", "agustus", "september", "oktober", "november", "desember"].map((bulan, index) => (
                                                        <option key={index} value={bulan}>
                                                            {bulan.charAt(0).toUpperCase() + bulan.slice(1)}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Nominal</label>
                                        <div className="control">
                                            <input
                                                type="text"
                                                className="input"
                                                value={nominal}
                                                onChange={handleNominalChange}
                                                required
                                                placeholder="Masukkan Nominal"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="field">
                                        <label className="label">Tambahan</label>
                                        <div className="control">
                                            <input
                                                type="text"
                                                className="input"
                                                value={tambahan}
                                                onChange={handleTambahanChange}
                                                placeholder="Masukkan Tambahan"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Potongan</label>
                                        <div className="control">
                                            <input
                                                type="text"
                                                className="input"
                                                value={potongan}
                                                onChange={handlePotonganChange}
                                                placeholder="Masukkan Potongan"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Keterangan</label>
                                <div className="control">
                                    <textarea
                                        className="textarea"
                                        rows={3}
                                        value={keterangan}
                                        onChange={(e) => setKeterangan(e.target.value)}
                                        placeholder="Tambahkan keterangan"
                                    />
                                </div>
                            </div>

                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-success is-fullwidth">Update Gaji</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
