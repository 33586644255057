import React, { useState, useRef } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import jsPDF from 'jspdf';
import "jspdf-autotable"; // Untuk tabel di PDF
import "../admin/css/data.css";
import {IoSaveOutline,IoArchive } from "react-icons/io5";

axios.defaults.withCredentials = true;

const fetcher = async (url) => {
    const response = await axios.get(url);
    if (response.status !== 200) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
    }
    return response.data;
};

const DataBulan = () => {
    const [bulan, setBulan] = useState('');
    const [tahun, setTahun] = useState('');
    const [fetchData, setFetchData] = useState(false);
    const [warning, setWarning] = useState('');
    const scrollRef = useRef(null);

    const { data: absensi, error } = useSWR(
        fetchData ? `${process.env.REACT_APP_API_BASE_URL}/absensicabang/get?bulan=${bulan}&tahun=${tahun}` : null,
        fetcher
    );

    const extractDates = (data) => {
        const dateSet = new Set();
        Object.keys(data).forEach((key) => {
            data[key].absensi.forEach((absensi) => {
                dateSet.add(absensi.tgl_absensi);
            });
        });
        return Array.from(dateSet).sort();
    };

    const dates = absensi ? extractDates(absensi) : [];

    const sortedCabangKeys = absensi ? Object.keys(absensi).sort((a, b) => {
        const cabangA = absensi[a].cabang.nama_cabang.toLowerCase();
        const cabangB = absensi[b].cabang.nama_cabang.toLowerCase();
        return cabangA.localeCompare(cabangB);
    }) : [];

    const handleMonthChange = (e) => {
        const [year, month] = e.target.value.split("-");
        setBulan(month);
        setTahun(year);
    };

    const handleTampilkanData = () => {
        if (!bulan || !tahun) {
            setWarning('Silahkan pilih bulan dan tahun.');
        } else {
            setWarning(''); 
            setFetchData(true);
        }
    };

    const handleExportPDF = (cabangId) => {
        const cabang = absensi[cabangId];
        const doc = new jsPDF({ orientation: 'landscape' }); // Orientasi landscape untuk lebih banyak kolom
        const chunkSize = 5; // Jumlah kolom per grup tanggal
    
        // Judul di bagian atas
        doc.text(`Data Absensi PT.BR Solusindo ${cabang.cabang.nama_cabang}`, 14, 10);
    
        // Posisi awal untuk tabel pertama
        let startYPosition = 20;
    
        // Membuat tabel dalam chunk
        for (let i = 0; i < dates.length; i += chunkSize) {
            const dateChunk = dates.slice(i, i + chunkSize);
    
            // Header tabel
            const tableColumn = ["Nama", ...dateChunk.flatMap(date => [
                `${new Date(date).toLocaleDateString('id-ID')}\nJam Masuk`, 
                "Jam Keluar"
            ])];
    
            // Menyusun baris tabel berdasarkan data absensi
            const tableRows = [];
    
            cabang.absensi.reduce((acc, record) => {
                const existingRow = acc.find(row => row.nama === record.karyawan.nama_lengkap);
                if (existingRow) {
                    existingRow.data[record.tgl_absensi] = {
                        jamMasuk: record.jam_masuk || 'Libur',
                        jamKeluar: record.jam_keluar || ''
                    };
                } else {
                    acc.push({
                        nama: record.karyawan.nama_lengkap,
                        data: {
                            [record.tgl_absensi]: {
                                jamMasuk: record.jam_masuk || 'Libur',
                                jamKeluar: record.jam_keluar || ''
                            }
                        }
                    });
                }
                return acc;
            }, []).forEach(row => {
                const rowData = [row.nama];
                dateChunk.forEach(date => {
                    rowData.push(row.data[date]?.jamMasuk || 'Libur', row.data[date]?.jamKeluar || '');
                });
                tableRows.push(rowData);
            });
    
            // Menghasilkan tabel di PDF dan atur posisi Y untuk chunk berikutnya
            doc.autoTable({
                head: [tableColumn],
                body: tableRows,
                startY: startYPosition,
                theme: 'grid',
                styles: {
                    fontSize: 8
                }
            });
    
            // Update posisi Y untuk tabel berikutnya
            startYPosition = doc.previousAutoTable.finalY + 10; // Spacer 10 unit antar tabel
        }
    
        // Menyimpan file PDF
        doc.save(`export-${cabang.cabang.nama_cabang}.pdf`);
    };
    const handleExportExcel = async (cabangId) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/export/excel`,
                { data: absensi[cabangId] },
                { responseType: 'blob' }
            );
            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `export-${absensi[cabangId].cabang.nama_cabang}.xlsx`);
            document.body.appendChild(link);
            link.click();
            
        } catch (error) {
            console.error('Error exporting Excel:', error);
        }
    };


    return (
        <div className="card is-shadowless">
            <form onSubmit={(e) => e.preventDefault()}>
                <label>
                    Bulan:
                    <input 
                        type="month" 
                        value={`${tahun}-${bulan}`} 
                        onChange={handleMonthChange} 
                        placeholder="YYYY-MM"
                    />
                </label>
                <button type="button" onClick={handleTampilkanData}>
                    Tampilkan Data
                </button>
            </form>
    
            {warning && <div style={{ color: 'red', marginTop: '10px' }}>{warning}</div>}
    
            {fetchData && error && error.response?.status === 404 && (
                <div>Data kosong untuk bulan dan tahun yang dipilih.</div>
            )}
    
            {sortedCabangKeys.length > 0 && sortedCabangKeys.map((key) => {
                const cabang = absensi[key];
                return (
                    <div key={key} className="card is-shadowless">
                        <h3>{cabang.cabang.nama_cabang}</h3>
                        <div className="scroll-container">
                            <div className="scrollable-container" ref={scrollRef}>
                                <table className="scrollable-table">
                                    <thead>
                                        <tr>
                                            <th rowSpan="2">Nama</th>
                                            {dates.map(date => (
                                                <th key={date} colSpan="2">{new Date(date).toLocaleDateString('id-ID')}</th>
                                            ))}
                                        </tr>
                                        <tr>
                                            {dates.map(date => (
                                                <React.Fragment key={date}>
                                                    <th>Jam Masuk</th>
                                                    <th>Jam Keluar</th>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cabang.absensi.reduce((acc, record) => {
                                            const existingRow = acc.find(row => row.nama === record.karyawan.nama_lengkap);
                                            if (existingRow) {
                                                existingRow.data[record.tgl_absensi] = {
                                                    jamMasuk: record.jam_masuk,
                                                    jamKeluar: record.jam_keluar
                                                };
                                            } else {
                                                acc.push({
                                                    nama: record.karyawan.nama_lengkap,
                                                    data: {
                                                        [record.tgl_absensi]: {
                                                            jamMasuk: record.jam_masuk,
                                                            jamKeluar: record.jam_keluar
                                                        }
                                                    }
                                                });
                                            }
                                            return acc;
                                        }, []).map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.nama}</td>
                                                {dates.map(date => (
                                                    <React.Fragment key={date}>
                                                        <td>{row.data[date]?.jamMasuk || ''}</td>
                                                        <td>{row.data[date]?.jamKeluar || ''}</td>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                        <button onClick={() => handleExportExcel(key)}><IoSaveOutline/>Export to Excel</button>
                        </div>
                        </div>
                        <button onClick={() => handleExportPDF(key)}><IoArchive/>Export to PDF</button>
                    </div>
                    
                );
            })}
        </div>
    );
};

export default DataBulan;
