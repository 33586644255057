import React, { useRef } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import "../admin/css/data.css";

const fetcher = (url) => axios.get(url).then(res => res.data);

const Data = () => {
    const now = new Date();
    const bulan = now.getMonth() + 1; 
    const tahun = now.getFullYear();
    const scrollRef = useRef(null);

    const { data: absensi, error } = useSWR(`${process.env.REACT_APP_API_BASE_URL}/absensicabang/get?bulan=${bulan}&tahun=${tahun}`, fetcher);

    if (error) return <div>Error loading data...</div>;
    if (!absensi) return <div>Loading...</div>;

    const extractDates = (data) => {
        const dateSet = new Set();
        Object.keys(data).forEach((key) => {
            data[key].absensi.forEach((absensi) => {
                dateSet.add(absensi.tgl_absensi);
            });
        });
        return Array.from(dateSet).sort((a, b) => new Date(a) - new Date(b));
    };

    const dates = extractDates(absensi);

    const sortedCabangKeys = Object.keys(absensi).sort((a, b) => {
        const cabangA = absensi[a].cabang.nama_cabang.toLowerCase();
        const cabangB = absensi[b].cabang.nama_cabang.toLowerCase();
        return cabangA.localeCompare(cabangB);
    });

    return (
        <div>
            {sortedCabangKeys.map((key) => {
                const cabang = absensi[key];
                return (
                    <div key={key} className="card is-shadowless">
                        <h3>{cabang.cabang.nama_cabang}</h3>
                        <div className="scroll-container">
                            {/* Scroll buttons can be enabled if needed */}
                            {/* <button className="scroll-button left" onClick={() => scroll('left')}>&#8249;</button> */}
                            {/* <button className="scroll-button right" onClick={() => scroll('right')}>&#8250;</button> */}
                            <div className="scrollable-container" ref={scrollRef}>
                                <table border="1" className="scrollable-table">
                                    <thead>
                                        <tr>
                                            <th rowSpan="2">Nama</th>
                                            {dates.map(date => (
                                                <th key={date} colSpan="2">{new Date(date).toLocaleDateString('id-ID')}</th>
                                            ))}
                                        </tr>
                                        <tr>
                                            {dates.map(date => (
                                                <React.Fragment key={date}>
                                                    <th>Jam Masuk</th>
                                                    <th>Jam Keluar</th>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cabang.absensi.reduce((acc, record) => {
                                            const existingRow = acc.find(row => row.nama === record.karyawan.nama_lengkap);
                                            if (existingRow) {
                                                existingRow.data[record.tgl_absensi] = {
                                                    jamMasuk: record.jam_masuk,
                                                    jamKeluar: record.jam_keluar
                                                };
                                            } else {
                                                acc.push({
                                                    nama: record.karyawan.nama_lengkap,
                                                    data: {
                                                        [record.tgl_absensi]: {
                                                            jamMasuk: record.jam_masuk,
                                                            jamKeluar: record.jam_keluar
                                                        }
                                                    }
                                                });
                                            }
                                            return acc;
                                        }, []).map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.nama}</td>
                                                {dates.map(date => (
                                                    <React.Fragment key={date}>
                                                        <td>{row.data[date]?.jamMasuk || ''}</td>
                                                        <td>{row.data[date]?.jamKeluar || ''}</td>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                           
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Data;
