import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Box ,Container, Card, CardContent, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
//import "./admin/css/KehadiranBulanKaryawan.css"

export const Kehadiranbulan = () => {
  const now = new Date();
  const [absensiData, setAbsensiData] = useState([]);
  const [error, setError] = useState(null);
  const [bulan, setBulan] = useState(now.getMonth() + 1);
  const [tahun, setTahun] = useState(now.getFullYear());
  const [warning, setWarning] = useState('');

  useEffect(() => {
    const fetchAbsensiData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/absensibulan/get?bulan=${bulan}&tahun=${tahun}`,{withCredentials: true});
        if (response.data.length === 0) {
          setError("Tidak ada data absensi untuk bulan ini.");
        } else {
          // Sorting berdasarkan tanggal terkecil dahulu
          const sortedData = response.data.sort((a, b) => new Date(a.tgl_absensi) - new Date(b.tgl_absensi));
          setAbsensiData(sortedData);
          setError(null); // Reset error jika ada data
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchAbsensiData();
  }, [bulan, tahun]);

  const handleMonthChange = (e) => {
    const [year, month] = e.target.value.split("-");
    setBulan(month);
    setTahun(year);
    setError(null); // Reset error saat bulan/tahun berubah
  };

  const handleTampilkanData = () => {
    if (!bulan || !tahun) {
      setWarning('Silahkan pilih bulan dan tahun.');
    } else {
      setWarning('');
    }
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: '20px' }}>
      <Card>
        <CardContent>
          <Typography variant="h5" style={{ marginBottom: '20px', textAlign: 'center' }}>Data Absensi Bulanan</Typography>
          <Box component="form" onSubmit={(e) => e.preventDefault()} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '10px', marginBottom: '20px' }}>
            <input
              type="month"
              value={`${tahun}-${bulan.toString().padStart(2, '0')}`}
              onChange={handleMonthChange}
              placeholder="YYYY-MM"
              style={{ padding: '8px', width: '100%' }}
            />
            <Button type="button" onClick={handleTampilkanData} variant="contained" sx={{ width: '100%', fontSize: '12px' }}>
              Tampilkan Data
            </Button>
          </Box>
          {warning && <Typography color="error" sx={{ textAlign: 'center' }}>{warning}</Typography>}
          {error ? (
            <Typography color="error" sx={{ textAlign: 'center' }}>{error}</Typography>
          ) : (
            <TableContainer component={Paper} className="responsive-container">
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"><strong>Tanggal</strong></TableCell>
                    <TableCell align="center"><strong>Jam Masuk</strong></TableCell>
                    <TableCell align="center"><strong>Jam Keluar</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {absensiData.map((absensi) => {
                    const tanggal = absensi.tgl_absensi
                      ? new Date(absensi.tgl_absensi).toLocaleDateString('id-ID', { day: '2-digit', month: '2-digit', year: 'numeric' })
                      : '';
                    return (
                      <TableRow key={absensi.id}>
                        <TableCell align="center">{tanggal}</TableCell>
                        <TableCell align="center">{absensi.jam_masuk}</TableCell>
                        <TableCell align="center">{absensi.jam_keluar || '-'}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Grid container spacing={2} style={{ marginTop: '20px' }}>
            <Grid item xs={12}>
              <Button component={NavLink} to="/dashboard" variant="contained" fullWidth>
                Kembali
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Kehadiranbulan;
