import React ,{useState }from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export const FormAddAdmin = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [role, setRole] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();

    const saveUser = async(e)=>{
        e.preventDefault();
        if(password !== confPassword) {
            setMsg('Password Konfrimasi tidak cocok')
            return;
        }
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users`,{
                name:name,
                email:email,
                role:role,
                password:password,
                confPassword:confPassword


            },{withCredentials: true});
            navigate('/admin/data')
            
        } catch (error) {
            if(error.response){
                setMsg(error.response.data.msg || "Terjadi kesalahan");
            }else{
                setMsg("Terjadi kesalahan");
            }
            
        }
    }

  return (
    <div>
    <div className="card is-shadowless">
      <div className="card-content">
        <div className="content">
        {/* <p className="title">Users</p> */}
        <p className="subtitle">Add New User</p>
          <form onSubmit={saveUser}>
            <p className="has-text-centered">{msg}</p>
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <input
                  type="text"
                  className="input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input
                  type="text"
                  className="input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Password</label>
              <div className="control">
                <input
                  type="password"
                  className="input"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="******"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Confirm Password</label>
              <div className="control">
                <input
                  type="password"
                  className="input"
                  value={confPassword}
                  onChange={(e) => setConfPassword(e.target.value)}
                  placeholder="******"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Role</label>
              <div className="control">
                <div className="select is-fullwidth">
                <select
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        required  // Menambahkan atribut required untuk memastikan user memilih role
                        >
                        <option value="">Pilih Role</option> {/* Pastikan ada opsi default yang tidak valid */}
                        <option value="admin">Admin</option>
                        <option value="user">User</option>
                        </select>
                </div>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button type="submit" className="button is-success">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  )
}
